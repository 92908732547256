import { React, useState } from "react";
import axios from 'axios';

const LOGIN_END_POINT = 'https://epoch.exchange'

export function AccountRegistration(props) {
  const [password, setPassword] = useState('')
  const [emailAddress, setEmailAddress] = useState('')
  const [publicKey, setPublicKeyReg] = useState('')
  const [isDone, setIsDone] = useState(false)

  async function sendAccountDetails() {
    await axios.post(LOGIN_END_POINT + "/registerUser", {password: password, emailAddress: emailAddress, publicKey: publicKey}).then((ret) => { 
      setIsDone(true)
    })
  }

  const passwordReg = (event) => {
    setPassword(event.target.value)
  }

  const emailAddressReg = (event) => {
    setEmailAddress(event.target.value)
  }

  const publicKeyReg = (event) => {
    setPublicKeyReg(event.target.value)
  }

  return (
      <div>
        {isDone === true ? (
            <div className="flex flex-col items-center justify-center h-screen">
            <h1 className="mt-20 bg-gradient-to-r from-orange-500 to-red-800 bg-clip-text text-4xl text-transparent">
              You're in!
            </h1>
            <button
              className="bg-orange-500 hover:bg-orange-800 text-white font-bold py-2 px-4 rounded mt-4"
              onClick={props.setRegistration}
            >
              Back to Login Screen
            </button>
          </div>          
        ) : (
          <div class="flex items-center justify-center min-h-screen">
            <div class="w-full max-w-xs mx-auto">
              <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <div class="mb-6">
                  <label class="block text-gray-700 text-sm font-bold mb-2" for="password">
                    Email address
                  </label>
                  <input className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="email" type="text" onChange={emailAddressReg}/>
                </div>

                <div class="mb-6">
                  <label class="block text-gray-700 text-sm font-bold mb-2" for="password">
                    Password
                  </label>
                  <input className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="password" type="password" onChange={passwordReg} placeholder="**************"/>
                  <p class="text-red-500 text-xs italic">Choose a password</p>
                </div>

                <div class="mb-6">
                  <label class="block text-gray-700 text-sm font-bold mb-2" for="password">
                    Public Key (ECC P-256)
                  </label>
                  <input className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="email" type="text" onChange={publicKeyReg}/>
                  <p class="text-red-500 text-xs italic">Set Public Key from Hardware Device</p>
                </div>

              </form>
              <div className="flex justify-center gap-10 p-4">
                <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-auto' onClick={sendAccountDetails}>
                  Complete
                </button>
                <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-auto' onClick={props.setRegistration}>
                  Back
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
}