import { useState } from "react";
import { sha256 } from "js-sha256"
import bigInt from "big-integer" //https://github.com/peterolson/BigInteger.js#readme
import axios from 'axios';

import { LoginCard } from "./Login"
import mySvg from './output.svg';

const LOGIN_END_POINT = 'https://epoch.exchange'

function randomBytes(b) {
  for (var a = []; b > 0; b--) a.push(Math.floor(Math.random() * 256));
  return a;
}

var raw_prime = "0xFFFFFFFF FFFFFFFF C90FDAA2 2168C234 C4C6628B 80DC1CD1 \
    29024E08 8A67CC74 020BBEA6 3B139B22 514A0879 8E3404DD \
    EF9519B3 CD3A431B 302B0A6D F25F1437 4FE1356D 6D51C245 \
    E485B576 625E7EC6 F44C42E9 A637ED6B 0BFF5CB6 F406B7ED \
    EE386BFB 5A899FA5 AE9F2411 7C4B1FE6 49286651 ECE45B3D \
    C2007CB8 A163BF05 98DA4836 1C55D39A 69163FA8 FD24CF5F \
    83655D23 DCA3AD96 1C62F356 208552BB 9ED52907 7096966D \
    670C354E 4ABC9804 F1746C08 CA18217C 32905E46 2E36CE3B \
    E39E772C 180E8603 9B2783A2 EC07A28F B5C55DF0 6F4C52C9 \
    DE2BCBF6 95581718 3995497C EA956AE5 15D22618 98FA0510 \
    15728E5A 8AACAA68 FFFFFFFF FFFFFFFF"
    
raw_prime = raw_prime.replace(/ /g, "")
raw_prime = window.BigInt(raw_prime)
let prime = bigInt(raw_prime)

let a = window.BigInt("0x" + sha256(randomBytes(32)))
let g = 2
const privKey = bigInt(a)
const pubKey = bigInt(g).modPow(privKey, prime).value

var shared_secret;

export function App() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [emailAddress, setemailAddress] = useState("")
  const [password, setPassword] = useState("");
  const [sessionId, setSessionId] = useState(-1)

  const updateemailAddress = (event) => {
    setemailAddress(event.target.value)
  }

  const updatePassword = (event) => {
    setPassword(event.target.value)
  }

  async function loginTrigger() {
    if(password == "") {
      // PROMPT USER
      console.log("Need password")
      return;
    }
    let payload = {emailAddress: emailAddress, password : password, pubKey:  pubKey.toString()}

    await axios.post(LOGIN_END_POINT + '/login', payload).then((res) => {
      // res contains DH server public key
      // TO DO: convert pubKey from string to bigInt, then s = (their_pubKey ** my_privKey) mod prime 
      shared_secret = sha256(bigInt(res.data.serverPubKey).modPow(privKey, prime).toString())
      console.log(res.data)
      if(res.data.success == true) {
        setSessionId(res.data.sessionId)
        setLoggedIn(true)
      }
      // Now a secrect is established and the client has a sessionId
    });
  }

  return (
    <div>
      {loggedIn === false ? (
      <div>
        <div className="min-h-screen flex items-center justify-center">
          <LoginCard upUser={updateemailAddress} upPass={updatePassword} logTrig={loginTrigger}/>
        </div>
      </div>
      ) : (
        <div className="bg-gray-800 flex flex-col items-center justify-center min-h-screen">
        <div>
          <img src={mySvg} alt="Description of SVG" width="300" height="300" />
        </div>
        <h1 className="bg-gradient-to-r from-yellow-500 to-green-800 bg-clip-text text-4xl text-transparent">
          Coming Soon
        </h1>
      </div>
      )}
    </div>
  );
}

export default App;