import React from "react"
import { useState } from "react";
import { AccountRegistration } from "./AccountRegistration"

export function LoginCard(props) {
    const [isRegistration, setIsRegistration] = useState(false)

    const setRegistration = () => {
      setIsRegistration(prevState => !prevState)
    }

    return (
      <div className="flex flex-col min-h-screen">
        <div className="flex-1">
          {isRegistration ? (
            <AccountRegistration setRegistration={setRegistration} />
          ) : (
            <div className="flex flex-col items-center justify-center h-screen">
              <div className="w-full max-w-xs mx-auto">
                <div className="text-center mt-20">
                  <h1 className="bg-gradient-to-r from-yellow-500 to-green-800 bg-clip-text text-4xl text-transparent">
                    Cutting-edge
                  </h1>
                  <h1 className="bg-gradient-to-r from-yellow-500 to-green-800 bg-clip-text text-4xl text-transparent">
                    Digital Assets Exchange
                  </h1>
                </div>
  
                <form className="mt-20 bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                  <div className="mb-4">
                  <div className="block text-green-700 text-sm font-bold mb-2">
                      Currently wrapping up development
                    </div>
                    <div className="block text-gray-700 text-sm font-bold mb-2">
                      Email address
                    </div>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="emailAddress"
                      type="text"
                      onChange={props.upUser}
                      placeholder="emailAddress"
                    />
                  </div>
  
                  <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                      Password
                    </label>
                    <input
                      className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                      id="password"
                      type="password"
                      onChange={props.upPass}
                      placeholder="**************"
                    />
                  </div>
  
                  <div className="flex items-center justify-between">
                    <button
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      type="button"
                      onClick={props.logTrig}
                    >
                      Sign In
                    </button>
                    <a
                      className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
                      href="#"
                    >
                      Forgot Password?
                    </a>
                  </div>
                </form>
              </div>
              <div className="w-full max-w-xs mx-auto">
                <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                  <div className="flex justify-center">
                    
                    <button
                      className="bg-yellow-300 items-center hover:bg-yellow-500 text-white font-bold py-2 px-20 rounded focus:outline-none focus:shadow-outline"
                      type="button"
                      onClick={setRegistration}
                    >
                      Register
                    </button>
                  </div>
                </form>
              </div>

              <p class='text-center text-gray-500 text-xs'> 
                &copy; Epoch Exchange Pty Ltd ACN: 680091178
              </p>
              <p class='text-center text-gray-500 text-xs'> 
                All programming code and rights reserved
              </p>
            </div>
          )}
        </div>
      </div>
    );
}